<template>
    <div class="v-history-wrap">
        <div class="hcommon-header">
            <div class="left-wrap" @click="$router.go(-1)">
                <i class="hvisit-back"></i>
                <span class="visit-title">发布历史</span>
            </div>
        </div>
        <div>
            <el-table :data="historyTable" border style="width: 100%">
                <el-table-column prop="versionNum" label="版本号" width="180">
                </el-table-column>
                <el-table-column prop="versionDate" label="版本日期" width="180">
                    <template slot-scope="scope">
                        <span>{{scope.row.versionDate?scope.row.versionDate.split(' ')[0]:''}}</span></template>
                </el-table-column>

                <el-table-column prop="where" label="发布到">
                    <template slot-scope="scope"><span>{{scope.row.publishEnv ==1?'测试':'生产'}}环境</span></template>
                </el-table-column>
                <el-table-column prop="remark" label="备注信息" :show-overflow-tooltip='true'>
                </el-table-column>
                <el-table-column prop="updateBy" label="操作人">
                </el-table-column>
                <el-table-column prop="updateDate" label="发布时间">
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <div class="edit-wrap">
                            <el-link type="primary" @click="onClickEdit(1,scope.row)">查看</el-link>
                            <el-popconfirm title="该操作会覆盖当前表单设计草稿,是否继续执行？" @confirm="onClickEdit(2,scope.row)">
                                <el-link slot="reference" type="primary">编辑</el-link>
                            </el-popconfirm>
                            <el-link type="primary" v-if="scope.row.publishEnv ==1&&scope.row.status!=2"
                                     @click="onClickEdit(3,scope.row)">发布
                            </el-link>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="footer-page-wrap">
                <el-pagination @current-change="handleCurrentChange" :current-page.sync="pageConfig.current"
                               :page-size="pageConfig.size" layout="total, prev, pager, next"
                               :total="pageConfig.total"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        getResearchPublishVersionHis
    } from "@/api/visit";
    import {
        mapActions,
        mapMutations,
        mapState
    } from 'vuex'

    export default {
        data() {
            return {}
        },
        computed: {
            ...mapState(['historyTable', 'pageConfig'])
        },
        created() {
            this.getResearchPublishVersions({
                researchCode: this.$route.query.code
            })
        },
        methods: {
            ...mapMutations(['setIsEdit', 'setSendDialogFormVisible', 'setSendForm', 'setDataList']),
            ...mapActions(['getResearchPublishVersions', 'onPublishVersion', 'getVisitList']),
            handleCurrentChange(v) {
                console.log(v);
            },
            onGoVisit() {
                this.$router.replace({
                    name: 'visitSelf',
                    query: ''
                })
            },
            goRouterJump(v) {
                this.setDataList([])
                this.$router.push({
                    name: 'visitSelf',
                    query: {
                        code: this.$route.query.code,
                        name: v.name || JSON.parse(localStorage.getItem('researchName')),
                        release: v.release || 0,
                        history: 1,
                        isEdit: 1
                    }
                })
            },
            onSetForm(v) {
                this.setSendForm({
                    publishEnv: parseInt(v.publishEnv),
                    versionNum: v.versionNum,
                    versionDate: v.versionDate,
                    remark: v.remark
                })
            },
            onClickEdit(type, v) {
                switch (type) {
                    case 1: //查看
                        this.goRouterJump(v)
                        localStorage.setItem('isSended', 0)
                        this.setIsEdit(1)
                        localStorage.setItem('isEdit', 1)
                        let str = `这是一个历史版本（版本号：${v.versionNum} 版本日期${v.versionDate}）于${v.publishDate}发布于${v.publishEnv == 1 ? '测试' : '生产'}环境`
                        localStorage.setItem('historyTitle', JSON.stringify(str))
                        localStorage.setItem('versionCode', JSON.stringify(v.versionCode))
                        this.getVisitList({
                            researchCode: this.$route.query.code,
                            versionCode: v.versionCode
                        })
                        break;
                    case 2: // 编辑
                        this.setIsEdit(0)
                        localStorage.setItem('isEdit', 0)
                        localStorage.setItem('versionCode', JSON.stringify(v.versionCode))
                        localStorage.setItem('versionCode', JSON.stringify(v.versionCode))
                        let obj1 = {
                            ...v
                        }
                        obj1.publishEnv = 0
                        this.onSetForm(obj1)
                        this.onPublishVersion({
                            versionCode: JSON.parse(localStorage.getItem('versionCode')),
                            researchCode: this.$route.query.code
                        })
                        this.goRouterJump(v)
                        break;
                    case 3: //发布
                        console.log(v);
                        this.setSendDialogFormVisible(true)
                        localStorage.setItem('versionCode', JSON.stringify(v.versionCode))
                        let obj = {
                            ...v
                        }
                        obj.publishEnv = 2
                        this.onSetForm(obj)
                        break;

                    default:
                        break;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-history-wrap {
        padding: 0 40px;

        .edit-wrap {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            a {
                margin-right: 10px;
            }
        }

        .footer-page-wrap {
            display: flex;
            justify-content: flex-end;
            min-height: 60px;
            align-items: center;
        }
    }

    .hcommon-header {
        padding: 20px 0;

        .left-wrap {
            display: flex;
            align-items: center;
            cursor: pointer;
        }

        .hvisit-back {
            display: block;
            width: 20px;
            height: 20px;
            background: url('../../public/img/icons/backsvg.svg') no-repeat;
            background-size: cover;
            margin-right: 6px;
        }
    }
</style>
